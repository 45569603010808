import React from 'react';

import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Stack } from '@mui/material';

const InfoIcon = () => (
  <Stack
    sx={{
      width: '1rem',
      height: '1rem',
      borderRadius: '50%',
      background: '#8080806e',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <FontAwesomeIcon style={{ fontSize: '8px' }} icon={faInfo} />
  </Stack>
);

export default InfoIcon;
