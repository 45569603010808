import React, { PropsWithoutRef, ReactNode } from 'react';

import { Stack, Typography } from '@mui/material';

type FormFieldDescriptionProps = {
  title?: string | ReactNode;
  subtitle?: string;
  required?: boolean;
};

export const RequiredAsterisk = () => (
  <Typography
    component="span"
    fontSize="1.2rem"
    sx={{ color: 'red', lineHeight: '.65em' }}
  >
    *
  </Typography>
);

const FormFieldDescription = ({
  title,
  subtitle,
  required,
}: PropsWithoutRef<FormFieldDescriptionProps>) => {
  return (
    <>
      {title && (
        <Stack alignItems="center" spacing={0.4} direction="row">
          {typeof title === 'string' || title instanceof String ? (
            <Typography variant="body2">{title}</Typography>
          ) : (
            title
          )}
          {required && <RequiredAsterisk />}
        </Stack>
      )}
      {subtitle && (
        <Typography variant="caption" sx={{ color: 'gray' }}>
          {subtitle}
        </Typography>
      )}
    </>
  );
};

export default FormFieldDescription;
