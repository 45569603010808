import { useEffect, useState } from 'react';

const useHasUserInteractedWithDom = () => {
  const [hasUserInteractedWithDom, setHasUserInteractedWithDom] =
    useState(false);

  const updateHasInteracted = (e: MouseEvent | KeyboardEvent) => {
    if (e.isTrusted) setHasUserInteractedWithDom(true);
  };

  useEffect(() => {
    if (hasUserInteractedWithDom) return undefined;

    document.body.addEventListener('click', updateHasInteracted, {
      once: true,
    });
    document.body.addEventListener('keydown', updateHasInteracted, {
      once: true,
    });

    return () => {
      document.body.removeEventListener('click', updateHasInteracted);
      document.body.removeEventListener('keydown', updateHasInteracted);
    };
  }, []);

  return hasUserInteractedWithDom;
};

export default useHasUserInteractedWithDom;
