// User form validation form has to be validated based on the page
// reseller-settings => internal-roles
// company-settings  => enduser-roles
// Profile => don't validate the permission section

import { useMemo } from 'react';

import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import emptyStringToNull from 'utils/emptyStringToNull';
import { UserFormUserType } from './types';

interface IProps {
  hidePermissionSection: boolean;
  userType: UserFormUserType;
}

const useUserValidationSchema = ({
  hidePermissionSection,
  userType,
}: IProps) => {
  const { t } = useTranslation('common');
  const isInternalUsersPage = userType === 'internal';

  return useMemo(() => {
    const userValidationSchema = yup.object().shape({
      first_name: yup.string().required(),
      last_name: yup
        .string()
        .nullable()
        .transform((value) => (value === '' ? null : value)),
      email: yup.string().required(),
      password: yup
        .string()
        .min(12)
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/,
          t(
            'auth.passwordMinRequirements',
            'Password must contain at least one lowercase letter, one uppercase letter, one number, and one special character',
          ),
        )
        .when('id', {
          is: (val: number) => val === 0 || val,
          then: (schema) => schema.nullable().transform(emptyStringToNull),
        }),

      username: yup
        .string()
        .required()
        .max(150)
        .matches(
          /^[\w@+.-]+$/,
          'Username may contain letters, digits, and the @/./+/-/_ symbols only.',
        ),
      group: yup.number().required(),
      user_channel_id: yup.string().nullable().transform(emptyStringToNull),
      preferred_language: yup.string(),
    });

    if (hidePermissionSection) return userValidationSchema;

    if (isInternalUsersPage) {
      return userValidationSchema.concat(
        yup.object({
          resellers: yup.array().required().min(1),
          group: yup.number().required(),
        }),
      );
    }

    return userValidationSchema.concat(
      yup.object({
        sites: yup.array().required().min(1),
        company: yup.string().required(),
      }),
    );
  }, []);
};

export default useUserValidationSchema;
