import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { FollowedBookmarkedType } from 'ts_shared';

import type { RootState } from 'store';

type BookmarkedFollowedState = {
  followed: FollowedBookmarkedType[];
  bookmarked: FollowedBookmarkedType[];
};

const initialState: BookmarkedFollowedState = {
  followed: [],
  bookmarked: [],
};

const followedBookmarkedFilterSlice = createSlice({
  name: 'followedBookmarkedFilter',
  initialState,
  reducers: {
    updateFollowedSelection: (
      state,
      action: PayloadAction<FollowedBookmarkedType[]>,
    ) => {
      state.followed = action.payload;
    },
    updateBookmarkedSelection: (
      state,
      action: PayloadAction<FollowedBookmarkedType[]>,
    ) => {
      state.bookmarked = action.payload;
    },
    resetFollowedBookmarkedSelection: (state) => {
      state.followed = initialState.followed;
      state.bookmarked = initialState.bookmarked;
    },
  },
});

export const getSelectedFollowedBookmarked = (state: RootState) =>
  state.followedBookmarkedFilter;

export const {
  updateFollowedSelection,
  updateBookmarkedSelection,
  resetFollowedBookmarkedSelection,
} = followedBookmarkedFilterSlice.actions;

export default followedBookmarkedFilterSlice.reducer;
