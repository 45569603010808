import React from 'react';

import { useSnackbar } from 'notistack';
import { Grid, InputAdornment, TextField, Button } from '@mui/material';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import { useTranslation } from 'react-i18next';
import { useCountdownTimer } from 'use-countdown-timer';

import { useResourceDeletionOtpCreateMutation } from 'services/api/ailyssaApi';

const RequestOtp = ({
  setOtp,
}: {
  setOtp: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { t } = useTranslation('common');
  const { start, countdown, isRunning } = useCountdownTimer({ timer: 300000 });
  const { enqueueSnackbar } = useSnackbar();
  const [dispatchOtp, { isLoading }] = useResourceDeletionOtpCreateMutation();

  const countdownSecs = countdown / 1000;

  const handleDispatchOtp = () => {
    dispatchOtp()
      .unwrap()
      .then(() => {
        enqueueSnackbar('OTP sent successfully, please check your email', {
          variant: 'success',
        });
        start();
      })
      .catch(() => {
        enqueueSnackbar(t('auth.authFailed', 'Authentication failed'), {
          variant: 'error',
        });
      });
  };

  return (
    <Grid container xs={12} sx={{ alignItems: 'end' }}>
      <Grid item xs>
        <TextField
          id="otp-field"
          type="text"
          placeholder={t('auth.otp', 'One-time Password')}
          fullWidth
          InputProps={{
            sx: { backgroundColor: 'ghostwhite' },
            startAdornment: (
              <InputAdornment position="start">
                <SafetyCheckIcon />
              </InputAdornment>
            ),
          }}
          variant="filled"
          onChange={(e) => setOtp(e.target.value)}
        />
      </Grid>

      <Grid item>
        <Button
          variant="outlined"
          onClick={handleDispatchOtp}
          disabled={isRunning || isLoading}
          sx={{ ml: 1 }}
        >
          {isRunning ? countdownSecs : t('auth.requestOtp', 'Request OTP')}
        </Button>
      </Grid>
    </Grid>
  );
};

export default RequestOtp;
