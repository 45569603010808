import React from 'react';

import { Paper, List } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useSearchData from 'hooks/useSearchData';
import ListItem from '../ListItem';
import { Column, SelectedItems, SubColOne, SubColTwo } from '../../types';
import useToggleSubColTwo from './useToggleSubColTwo';
import NoOptionMsg from '../NoOptionMsg';
import ColumnHeaderWithSearch from '../ColumnList/ColumnHeaderWithSearch';

interface IProps {
  selectedSubColOne?: SubColOne;
  selectedItems: SelectedItems;
  setSelectedItems: (items: SelectedItems) => void;
  selectedColumn?: Column;
  subColTwoHeader: string;
  subColOneHeader: string;
  handleSelectSubColTwo?: (
    subColTwo: SubColTwo,
    selectedSubColOne: SubColOne,
    selectedColumn: Column,
  ) => void;
  disableSubColTwoSelection?: boolean;
}
const SubColTwoList = ({
  selectedSubColOne,
  selectedItems,
  setSelectedItems,
  selectedColumn,
  subColTwoHeader,
  subColOneHeader,
  handleSelectSubColTwo,
  disableSubColTwoSelection,
}: IProps) => {
  const { t } = useTranslation('common');
  const handleToggleSubColOne = useToggleSubColTwo({
    selectedItems,
    setSelectedItems,
  });

  const [subColTwoItems, , searchValue, setSearchValue] = useSearchData({
    data: selectedSubColOne?.subColTwo,
    lookupField: 'name',
  });

  return (
    <Paper sx={{ height: '100%' }}>
      <ColumnHeaderWithSearch
        searchColVal={searchValue}
        setSearchColVal={setSearchValue}
        columnHeader={subColTwoHeader}
      />
      <List
        sx={{ height: 'calc(100% - 48px)', overflowY: 'auto' }}
        disablePadding
      >
        {selectedSubColOne?.subColTwo?.length === 0 && (
          <NoOptionMsg
            message={`${t(
              'common.selected',
              'Selected',
            )} ${subColOneHeader.toLowerCase()} ${t(
              'common.hasNo',
              'has no',
            )} ${subColTwoHeader.toLowerCase()}`}
          />
        )}

        {selectedSubColOne &&
          selectedColumn &&
          subColTwoItems?.map((subColTwo) => (
            <ListItem
              key={subColTwo.id}
              handleSelect={() => {
                if (handleSelectSubColTwo)
                  handleSelectSubColTwo(
                    subColTwo,
                    selectedSubColOne,
                    selectedColumn,
                  );
                else
                  handleToggleSubColOne(
                    subColTwo,
                    selectedSubColOne,
                    selectedColumn,
                  );
              }}
              handleMouseEnter={() => {}}
              checked={selectedItems.subColTwo.includes(subColTwo.id)}
              id={subColTwo.id}
              name={subColTwo.name}
              disableSelection={disableSubColTwoSelection}
            />
          ))}
      </List>
    </Paper>
  );
};

export default SubColTwoList;
