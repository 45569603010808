import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import React, { cloneElement, useRef } from 'react';
import { useContextMenu } from 'react-contexify';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton } from '@mui/material';

import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import CustomContextMenu from 'libs/Contextify/CustomContextMenu';
import WithPermissions from 'components/common/Permissions';
import { Section } from '../sections';
import { getRecursiveSubTabsPermission } from './utils';
import AppbarSubMenu from './AppbarSubMenu';
import AppbarNavLink from './AppbarLink';

interface IProps {
  sections: Section[];
  anchorEl?: JSX.Element;
  id?: string;
}

export function getMenuPosition(triggerRef: React.RefObject<HTMLElement>) {
  if (!triggerRef.current) return null;

  const { left, bottom } = triggerRef.current.getBoundingClientRect();

  return { x: left, y: bottom + 8 };
}

const MENU_ID = 'app-bar-menu';

const AppbarMenu = ({ sections, anchorEl, id }: IProps) => {
  const triggerRef = useRef<HTMLDivElement>(null);
  const { data: curUser } = useUsersCurrentRetrieveQuery();

  const platformConfig = usePlatformConfigContext();

  const { show } = useContextMenu({
    id: MENU_ID + id,
  });

  // TODO : Check recursively for subTabs
  const hasSomeTabAccess = sections.some((section) =>
    section.subTabs
      ? section.subTabs.some(
          (subTab) =>
            !subTab.tab_access || curUser?.tab_access[subTab.tab_access],
        )
      : !section.tab_access || curUser?.tab_access[section.tab_access],
  );

  if (!hasSomeTabAccess) return null;

  return (
    <>
      {anchorEl ? (
        cloneElement(anchorEl, {
          ref: triggerRef,
          onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) =>
            show({
              event: e,
              position: getMenuPosition(triggerRef),
            }),
        })
      ) : (
        <IconButton
          onClick={(e) =>
            show({
              event: e,
            })
          }
          color="info"
        >
          <MoreHorizIcon />
        </IconButton>
      )}
      <CustomContextMenu
        id={MENU_ID + id}
        style={{
          background: platformConfig.navbar_menu_color,
        }}
      >
        {sections.map((section) =>
          section.subTabs ? (
            <WithPermissions
              key={section.id}
              permission={getRecursiveSubTabsPermission(section)}
            >
              <AppbarSubMenu {...section} />
            </WithPermissions>
          ) : (
            // Single Menu Item
            <AppbarNavLink isMenuLink key={section.id} {...section} />
          ),
        )}
      </CustomContextMenu>
    </>
  );
};
export default AppbarMenu;
