import React from 'react';

import { Box, ListItemText } from '@mui/material';
import { Submenu } from 'react-contexify';

import { usePlatformConfigContext } from 'contexts/PlatformConfigContext';
import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import { Section } from '../sections';
import AppbarNavLink from './AppbarLink';
import useIsNavLinkSelected from './useIsNavLinkSelected';

const AppbarSubMenu = ({ id, name, subTabs, ...rest }: Section) => {
  const { data: curUser } = useUsersCurrentRetrieveQuery();
  const platformConfig = usePlatformConfigContext();

  const isSomeTabAccessTrue =
    subTabs &&
    subTabs.some(
      (subTab) => !subTab.tab_access || curUser?.tab_access[subTab.tab_access],
    );

  if (!subTabs || !isSomeTabAccessTrue) return null;

  const isOneChildActive = useIsNavLinkSelected()({
    id,
    name,
    subTabs,
    ...rest,
  });

  return (
    <Box
      sx={{
        bgcolor: isOneChildActive
          ? platformConfig.navbar_hover_color
          : 'default',
      }}
    >
      <Submenu
        key={id}
        style={{
          background: platformConfig.navbar_menu_color,
        }}
        label={<ListItemText sx={{ color: 'white' }} primary={name} />}
      >
        {/* Sub menu link */}
        {subTabs.map((subMenu) =>
          subMenu.subTabs ? (
            <AppbarSubMenu key={subMenu.id} {...subMenu} />
          ) : (
            <AppbarNavLink isMenuLink key={subMenu.id} {...subMenu} />
          ),
        )}
      </Submenu>
    </Box>
  );
};

export default AppbarSubMenu;
