import React, { PropsWithoutRef } from 'react';

import {
  CardHeader as MuiCardHeader,
  CardHeaderProps,
  ThemeProvider,
  createTheme,
  Theme,
  Stack,
} from '@mui/material';

const newThemeComponent = {
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        fontSize: 'smaller',
        fontWeight: 'bolder',
      },
    },
  },
};

interface SettingsCardHeaderProps extends PropsWithoutRef<CardHeaderProps> {
  flexGrow?: number;
}

const SettingsCardHeader = ({
  flexGrow = 0,
  sx,
  action,
  ...props
}: SettingsCardHeaderProps) => (
  <ThemeProvider
    theme={(outterTheme: Theme) =>
      createTheme({
        ...outterTheme,
        components: { ...outterTheme.components, ...newThemeComponent },
      })
    }
  >
    <Stack sx={{ background: 'lightgrey' }} direction="row" alignItems="center">
      <MuiCardHeader
        {...props}
        sx={{
          bgcolor: 'lightgray',
          '& .MuiCardHeader-content': {
            flexGrow,
          },
          ...sx,
        }}
      />
      {action}
    </Stack>
  </ThemeProvider>
);

export default SettingsCardHeader;
