import React, { useEffect, useState } from 'react';

import {
  FieldValues,
  Path,
  PathValue,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { IconButton, InputAdornment, Box, Button } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import generateNewPassword from 'utils/generateNewPassword';
import PasswordValidator from '../PasswordValidator';
import { TextFieldRenderer, TextFieldRendererProps } from '../FieldRenderers';

interface IProps<T extends FieldValues>
  extends Omit<TextFieldRendererProps<T>, 'type'> {
  creatingNewUser?: boolean;
  setValue?: UseFormSetValue<T>;
  hideRegenerateButton?: boolean;
  isInvitedUser?: boolean;
}

const PasswordFieldRenderer = <T extends FieldValues>({
  textFieldProps,
  creatingNewUser,
  setValue,
  hideRegenerateButton,
  isInvitedUser,
  ...otherProps
}: IProps<T>) => {
  const { control, name } = otherProps;
  const [isFocused, setIsFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const password = useWatch({ control, name });

  const handleInputClick = () => {
    setIsFocused(true);
  };

  const handleIconClick = () => {
    setShowPassword(!showPassword);
    setIsFocused(true);
  };

  const setRandomPassword = () => {
    if (setValue) {
      setValue(name, generateNewPassword() as PathValue<T, Path<T>>, {
        shouldDirty: true,
      });
    }
  };

  useEffect(() => {
    if (creatingNewUser) {
      setRandomPassword();
    }
  }, []);

  return (
    <Box>
      <TextFieldRenderer
        textFieldProps={{
          InputProps: {
            onClick: handleInputClick,
            onBlur: () => setIsFocused(false),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleIconClick}
                  onBlur={() => setIsFocused(!isFocused)}
                >
                  {showPassword || creatingNewUser ? (
                    <Visibility />
                  ) : (
                    <VisibilityOff />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          },
          ...textFieldProps,
        }}
        type={showPassword || creatingNewUser ? 'text' : 'password'}
        disableTextField={creatingNewUser || isInvitedUser}
        fieldAction={
          creatingNewUser && !hideRegenerateButton ? (
            <Button variant="outlined" onClick={setRandomPassword}>
              REGENERATE
            </Button>
          ) : undefined
        }
        {...otherProps}
      />
      <PasswordValidator isVisible={isFocused} password={password} />
    </Box>
  );
};

export default PasswordFieldRenderer;
