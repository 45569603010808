import { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

const useIsMinimalMode = () => {
  const [isMinimal, setIsMinimal] = useState(false);

  const [params] = useSearchParams();

  useEffect(() => {
    // If url has minimal=true, store it locally.
    if (params.get('minimal') === 'true') {
      localStorage.setItem('minimal', 'true');
    }

    const shouldDisplayAppbar = localStorage.getItem('minimal');

    setIsMinimal(Boolean(shouldDisplayAppbar));
  }, []);

  return isMinimal;
};

export default useIsMinimalMode;
