import React, { StrictMode, lazy } from 'react';

import { createRoot } from 'react-dom/client';
// this import fixes the styled_default vite error
import '@mui/material/styles/styled';
import 'chartjs-adapter-moment';
import { BrowserRouter, Route, Navigate } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Chart, registerables } from 'chart.js';
import { LicenseInfo } from '@mui/x-license';
import { I18nextProvider } from 'react-i18next';

import ChartDataLabels from 'chartjs-plugin-datalabels';
import zoomPlugin from 'chartjs-plugin-zoom';

import SnackbarProviderWithDismiss from 'components/SnackbarProviderWithDismiss';
import PlatformConfigContextProvider from 'contexts/PlatformConfigContext';

import './index.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

import initYupLocale from 'libs/yup';
import FrPlatformCtxProvider from 'contexts/frPlatformCtx';
import MainThemeProvider from 'components/MainThemeProvider';
import SuspenseWithLoading from 'components/common/SuspenseWithLoading';
import ConfirmationDialogProvider from 'contexts/ConfirmationDialogProvider';
import CustomRoutes from 'components/common/CustomRoutes';
import SocketContextProvider from 'socket';
import { SnackbarUtilsConfigurator } from 'components/SnackbarUtilsConfigurator';
import WithPermissions from 'components/common/Permissions';
import CommonPermissions from 'permissions/CommonPermissions';

import CustomLocalizationProvider from 'components/CustomLocalizationProvider';
import AuthorisedRoutes, { ProtectedRoute } from './AuthorisedRoutes';
import i18n from './i18n';

import store from './store';

import Header from './components/common/header';
import { initSentry } from './utils/sentry';

const AlertRoutes = lazy(() => import('tabs/alerts'));
const AuthRoutes = lazy(() => import('components/Auth'));
const InspectionRoutes = lazy(() => import('tabs/inspection'));
const FootfallRoutes = lazy(() => import('tabs/footfall'));
const WorkhourTrackingRoutes = lazy(() => import('tabs/workhourTracking'));
const VehicleManagementRoutes = lazy(() => import('tabs/vehicleManagement'));
const Permissions = lazy(() => import('tabs/permission'));
const SettingsPage = lazy(() => import('components/Settings'));
const CompanySettingsPage = lazy(
  () => import('components/Settings/CompanySettingsPage'),
);
const ResellerSettingsPage = lazy(
  () => import('components/Settings/ResellerSettingsPage'),
);
const CameraHealthCheckRoutes = lazy(() => import('tabs/cameraHealthCheck'));
const TimelapseLogsRoutes = lazy(() => import('tabs/timelapse'));
const ControlPanelPage = lazy(() => import('tabs/controlPanelPage'));
const UsecaseUsagePage = lazy(() => import('tabs/usecaseUsage'));
const VendorRoutes = lazy(() => import('tabs/vendor'));
const CaptureLogsRoutes = lazy(() => import('tabs/captureLogs'));
const AccessControlRoutes = lazy(() => import('tabs/accessControl'));
const AuditTrailChangesRoutes = lazy(() => import('tabs/auditTrailChanges'));
const AuditTrailActionsRoutes = lazy(() => import('tabs/auditTrailActions'));
const UserPermissionManagementRoutes = lazy(
  () => import('tabs/userPermissionManagement'),
);
const ResellerStatsRoutes = lazy(() => import('tabs/resellerStats'));

if (import.meta.env.MODE === 'production') {
  initSentry(); // init sentry only in production
}

Chart.register(ChartDataLabels);
Chart.register(zoomPlugin);
Chart.register(...registerables);

LicenseInfo.setLicenseKey(import.meta.env.VITE_MUI_LICENSE_KEY || '');

const container = document.getElementById('root');
const root = createRoot(container!);

initYupLocale();

root.render(
  <StrictMode>
    <I18nextProvider i18n={i18n}>
      <SuspenseWithLoading>
        <FrPlatformCtxProvider>
          <BrowserRouter>
            <Provider store={store}>
              <SocketContextProvider>
                <PlatformConfigContextProvider>
                  <MainThemeProvider>
                    <CustomLocalizationProvider>
                      <SnackbarProviderWithDismiss>
                        <ConfirmationDialogProvider>
                          <SnackbarUtilsConfigurator />
                          <CustomRoutes>
                            <Route path="/*" element={<AuthRoutes />} />
                            <Route
                              path="/"
                              element={
                                <ProtectedRoute>
                                  <Header />
                                </ProtectedRoute>
                              }
                            >
                              <Route
                                index
                                element={<Navigate to="/alerts" replace />}
                              />
                              {/* Tabs */}
                              <Route
                                path="/alerts/*"
                                element={
                                  <AuthorisedRoutes tab_name="alerts">
                                    <AlertRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/inspection/*"
                                element={
                                  <AuthorisedRoutes tab_name="inspection">
                                    <InspectionRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/vehicle-management/*"
                                element={
                                  <AuthorisedRoutes tab_name="vehicle_management">
                                    <VehicleManagementRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/footfall/*"
                                element={
                                  <AuthorisedRoutes tab_name="footfall">
                                    <FootfallRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/workhour-tracking/*"
                                element={
                                  <AuthorisedRoutes tab_name="workhour">
                                    <WorkhourTrackingRoutes />
                                  </AuthorisedRoutes>
                                }
                              />
                              <Route
                                path="/camera-health/*"
                                element={
                                  <AuthorisedRoutes tab_name="camera_health_check">
                                    <CameraHealthCheckRoutes />
                                  </AuthorisedRoutes>
                                }
                              />

                              <Route
                                path="/capture-logs/*"
                                element={<CaptureLogsRoutes />}
                              />

                              <Route
                                path="access-control/*"
                                element={<AccessControlRoutes />}
                              />

                              <Route
                                path="timelapse/*"
                                element={
                                  <AuthorisedRoutes tab_name="timelapse">
                                    <TimelapseLogsRoutes />
                                  </AuthorisedRoutes>
                                }
                              />

                              {/* Operations Pages */}
                              <Route
                                path="/control-panel/*"
                                element={<ControlPanelPage />}
                              />

                              <Route
                                path="/usecase-usage/*"
                                element={<UsecaseUsagePage />}
                              />

                              <Route
                                path="/vendor/*"
                                element={<VendorRoutes />}
                              />

                              <Route
                                path="/audit-trail-changes/*"
                                element={<AuditTrailChangesRoutes />}
                              />
                              <Route
                                path="/audit-trail-actions/*"
                                element={<AuditTrailActionsRoutes />}
                              />

                              <Route
                                path="/user-permission-management/*"
                                element={<UserPermissionManagementRoutes />}
                              />

                              <Route
                                path="/reseller-stats/*"
                                element={<ResellerStatsRoutes />}
                              />

                              {/* Standalone pages */}
                              <Route
                                path="/permissions"
                                element={<Permissions />}
                              />
                              <Route
                                path="/:tab/settings"
                                element={
                                  <WithPermissions
                                    permission={CommonPermissions.VIEW_SETTINGS}
                                  >
                                    <SettingsPage />
                                  </WithPermissions>
                                }
                              />
                              <Route
                                path="/:tab/company-settings"
                                element={
                                  <WithPermissions
                                    permission={
                                      CommonPermissions.VIEW_COMPANY_SETTINGS
                                    }
                                  >
                                    <CompanySettingsPage />
                                  </WithPermissions>
                                }
                              />
                              <Route
                                path="/:tab/reseller-settings"
                                element={
                                  <WithPermissions
                                    permission={
                                      CommonPermissions.VIEW_RESELLER_SETTINGS
                                    }
                                  >
                                    <ResellerSettingsPage />
                                  </WithPermissions>
                                }
                              />
                            </Route>
                          </CustomRoutes>
                        </ConfirmationDialogProvider>
                      </SnackbarProviderWithDismiss>
                    </CustomLocalizationProvider>
                  </MainThemeProvider>
                </PlatformConfigContextProvider>
              </SocketContextProvider>
            </Provider>
          </BrowserRouter>
        </FrPlatformCtxProvider>
      </SuspenseWithLoading>
    </I18nextProvider>
  </StrictMode>,
);
