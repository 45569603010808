import React from 'react';

import NotificationsIcon from '@mui/icons-material/Notifications';
import { Badge, IconButton } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useThrottledRefetchListener } from 'socket';

import {
  useUsersUnreadNotificationCountRetrieveQuery,
  useUsersCurrentRetrieveQuery,
} from 'services/api/general';
import CustomPopover from 'components/common/CustomPopover';
import useRequestSystemNotificationPermission from 'hooks/useRequestSystemNotificationPermission';
import NotificationPanel from './components/NotificationPanel';
import useSendNotification from './useSendNotification';
import { type NotificationType } from './types';

const UserNotifications = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { data: curUser } = useUsersCurrentRetrieveQuery();
  const handleSendNotification = useSendNotification();

  const {
    data: count,
    refetch,
    isUninitialized,
  } = useUsersUnreadNotificationCountRetrieveQuery(undefined);

  useRequestSystemNotificationPermission();

  useThrottledRefetchListener({
    message: 'new_notification',
    refetch: (event: NotificationType) => {
      refetch()
        .unwrap()
        .then(() => handleSendNotification(event))
        .catch((err) =>
          enqueueSnackbar(JSON.stringify(err) || 'Error occured', {
            variant: 'error',
          }),
        );
    },
    stopListening: !curUser?.notification_settings.enabled,
    deps: [handleSendNotification],
    isUninitialized,
  });

  return (
    <CustomPopover
      trigger={
        <IconButton>
          <Badge color="primary" badgeContent={count}>
            <NotificationsIcon sx={{ fontSize: '28px' }} />
          </Badge>
        </IconButton>
      }
      popoverProps={{
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
      }}
    >
      <NotificationPanel />
    </CustomPopover>
  );
};

export default UserNotifications;
