import {
  type BaseQueryFn,
  type FetchArgs,
  type FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from '@reduxjs/toolkit/query/react';

import * as queryString from 'query-string';

const baseQuery = fetchBaseQuery({
  baseUrl: import.meta.env.VITE_API_BASE_URL,
  paramsSerializer: (params) => queryString.stringify(params),
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const currentUrl = window.location.href;

  if (
    result.error &&
    result.error.status === 401 &&
    !currentUrl.includes('/login') &&
    !currentUrl.includes('/activate-account')
  ) {
    window.location.href = '/login';
  }

  return result;
};

export const alertStatsTags = [
  'AlertCounts',
  'AlertCountsByBlock',
  'AlertCountsByDate',
  'AlertCountsRolling',
];

export const tags = [
  'Site',
  'Camera',
  'Alert',
  'AlertBase',
  'CameraBase',
  'CameraSudoSettings',
  'User',
  'AlertComments',
  'Group',
  'AywaProcessingNodes',
  'CurrentUser',
  'FrCalibration',
  'FrPeople',
  'FrAuthorizations',
  'VaCalibration',
  'VaRecords',
  'VaRecordsInfo',
  'LicensePlates',
  'LicensePlatesAuth',
  'Reseller',
  'CompanyUser',
  'Company',
  'ResellerUser',
  'Zone',
  'UserUnreadNotificationsCount',
  'UserNotifications',
  'ApnCameraRelations',
  ...alertStatsTags,
];

const emptySplitApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: tags,
});

export default emptySplitApi;
