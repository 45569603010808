import React, { useMemo } from 'react';

import { v4 as uuidv4 } from 'uuid';
import i18n from 'i18n';
import HomeIcon from '@mui/icons-material/Home';
import VideoCameraBackIcon from '@mui/icons-material/VideoCameraBack';
import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PlaylistAddCheckCircleIcon from '@mui/icons-material/PlaylistAddCheckCircle';
import { Tabs } from 'ts_shared';
import CommentIcon from '@mui/icons-material/Comment';
import CollectionsIcon from '@mui/icons-material/Collections';
import EditNoteIcon from '@mui/icons-material/EditNote';

import AlertPermissions from 'tabs/alerts/AlertPermissions';
import VmPermissions from 'tabs/vehicleManagement/VmPermissions';
import { useUsersCurrentRetrieveQuery } from 'services/api/general';
import UsecaseUsagePermissions from 'tabs/usecaseUsage/UsecaseUsagePermissions';
import CommonPermissions from 'permissions/CommonPermissions';
import AuditTrailPermissions from 'tabs/auditTrailChanges/AuditTrailPermissions';
import AccessControlPermissions from 'tabs/accessControl/AccessControlPermissions';
import UserPermissionManagementPermissions from 'tabs/userPermissionManagement/UserPermissionManagementPermissions';
import ResellerStatsPermissions from 'tabs/resellerStats/ResellerStatsPermissions';

const tn = (key: string, defaultText: string) => {
  return i18n.t(`common.${key}`, defaultText, { ns: 'common' });
};

interface Route {
  name: string;
  icon: JSX.Element;
  url: string;
  permission: string;
}
export interface Section {
  id: string;
  name: string;
  engName: string; // for sorting alphabetically
  path: string;
  tab_access?: keyof Tabs;
  permission: string;
  routes: Route[];
  subTabs?: Omit<Section[], 'subTabs'>;
}

export type Sections = Section[];

const sections = (): Sections => [
  {
    id: uuidv4(),
    name: tn('alerts', 'Alerts'),
    engName: 'Alerts',
    path: 'alerts',
    tab_access: 'alerts',
    permission: AlertPermissions.VIEW_ALERT_TAB,
    routes: [
      {
        name: tn('cameras', 'Cameras'),
        icon: <VideoCameraBackIcon />,
        url: 'cameras',
        permission: AlertPermissions.VIEW_ALERT_CAMERA_STREAMS,
      },
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: AlertPermissions.VIEW_ALERT_DASHBOARD,
      },
      {
        name: tn('summary', 'Summary'),
        icon: <LibraryBooksIcon />,
        url: 'summary',
        permission: AlertPermissions.VIEW_ALERT_SUMMARY,
      },
      {
        name: tn('configuration', 'Configuration'),
        icon: <DisplaySettingsIcon />,
        url: 'configuration',
        permission: AlertPermissions.VIEW_ALERT_CONFIGURATION,
      },
    ],
  },
  {
    id: uuidv4(),
    name: tn('inspection', 'Inspection'),
    engName: 'Inspection',
    path: 'inspection',
    tab_access: 'inspection',
    permission: '',
    routes: [
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: '',
      },
    ],
  },

  {
    id: uuidv4(),
    name: tn('vehicleManagement', 'Vehicle Management'),
    engName: 'Vehicle Management',
    path: 'vehicle-management',
    tab_access: 'vehicle_management',
    permission: VmPermissions.VIEW_VM_TAB,
    routes: [
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: VmPermissions.VIEW_VM_DASHBOARD,
      },
      {
        name: tn('configuration', 'Configuration'),
        icon: <DisplaySettingsIcon />,
        url: 'configure',
        permission: VmPermissions.VIEW_VM_CONFIGURATION,
      },
      {
        name: tn('manage', 'Manage'),
        icon: <PlaylistAddCheckCircleIcon />,
        url: 'record-management',
        permission: VmPermissions.VIEW_VM_MANAGEMENT,
      },
    ],
  },
  {
    id: uuidv4(),
    name: tn('cameraHealth', 'Camera Health'),
    engName: 'Camera Health',
    path: 'camera-health',
    tab_access: 'camera_health_check',
    permission: '',
    routes: [
      {
        name: tn('dashboard', 'Dashboard'),
        icon: <HomeIcon />,
        url: 'dashboard',
        permission: '',
      },
    ],
  },

  {
    id: uuidv4(),
    name: tn('crowdSurveillance', 'Crowd Surveillance'),
    engName: 'Crowd Surveillance',
    path: 'footfall',
    tab_access: 'footfall',
    permission: '',
    subTabs: [
      {
        id: uuidv4(),
        name: tn('footfall', 'Footfall'),
        engName: 'Footfall',
        path: 'footfall',
        permission: '',
        tab_access: 'footfall',
        routes: [
          {
            name: tn('dashboard', 'Dashboard'),
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
          {
            name: tn('configuration', 'Configuration'),
            icon: <DisplaySettingsIcon />,
            url: 'configuration',
            permission: AlertPermissions.VIEW_ALERT_CONFIGURATION,
          },
        ],
      },
    ],
    routes: [],
  },
  {
    id: uuidv4(),
    name: tn('productivity', 'Productivity'),
    engName: 'Productivity',
    tab_access: 'workhour',
    permission: '',
    path: 'workhour-tracking',
    subTabs: [
      {
        id: uuidv4(),
        name: tn('workhourTracking', 'Workhour Tracking'),
        engName: 'Workhour Tracking',
        permission: '',
        tab_access: 'workhour',
        path: 'workhour-tracking',
        routes: [
          {
            name: tn('dashboard', 'Dashboard'),
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
          {
            name: tn('configuration', 'Configuration'),
            icon: <DisplaySettingsIcon />,
            url: 'configuration',
            permission: AlertPermissions.VIEW_ALERT_CONFIGURATION,
          },
        ],
      },
    ],
    routes: [],
  },
  {
    id: uuidv4(),
    name: tn('operations', 'Operations'),
    engName: 'Operations',
    permission: '',
    path: '',
    subTabs: [
      {
        id: uuidv4(),
        name: tn('useCaseUsage', 'Usecase Usage'),
        engName: 'Usecase Usage',
        permission: UsecaseUsagePermissions.VIEW_USECASE_USAGE_TAB,
        path: 'usecase-usage',
        routes: [
          {
            name: tn('dashboard', 'Dashboard'),
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
        ],
      },
      {
        id: uuidv4(),
        name: tn('controlPanel', 'Control Panel'),
        engName: 'Control Panel',
        path: 'control-panel',
        permission: CommonPermissions.WRITE_CONTROL_PANEL_PAGE,
        routes: [
          {
            name: tn('dashboard', 'Dashboard'),
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
        ],
      },

      {
        id: uuidv4(),
        name: tn('vendor', 'Vendor'),
        engName: 'Vendor',
        path: 'vendor',
        permission: 'superuser',
        routes: [
          {
            name: tn('dashboard', 'Dashboard'),
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: 'superuser',
          },
        ],
      },

      {
        id: uuidv4(),
        name: tn('auditTrail', 'Audit Trail'),
        engName: 'Audit Trail',
        path: '',
        permission: '',
        subTabs: [
          {
            id: uuidv4(),
            name: tn('changes', 'Changes'),
            engName: 'Changes',
            permission: AuditTrailPermissions.VIEW_AUDIT_LOG_PAGE,
            path: 'audit-trail-changes',
            routes: [
              {
                name: 'Dashboard',
                icon: <HomeIcon />,
                url: 'dashboard',
                permission: AuditTrailPermissions.VIEW_AUDIT_LOG_PAGE,
              },
            ],
          },

          {
            id: uuidv4(),
            name: tn('Actions', 'Actions'),
            engName: 'Actions',
            permission: AuditTrailPermissions.VIEW_AUDIT_LOG_PAGE,
            path: 'audit-trail-actions',
            routes: [
              {
                name: 'Dashboard',
                icon: <HomeIcon />,
                url: 'dashboard',
                permission: AuditTrailPermissions.VIEW_AUDIT_LOG_PAGE,
              },
            ],
          },
        ],
        routes: [],
      },
      {
        id: uuidv4(),
        name: tn('userPermissionManagement', 'User Permission Management'),
        path: 'user-permission-management',
        engName: 'User Permission Management',
        permission:
          UserPermissionManagementPermissions.USER_PERMISSION_MANAGEMENT_READ,
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
        ],
      },

      {
        id: uuidv4(),
        name: 'Reseller Stats',
        path: 'reseller-stats',
        engName: 'Reseller Stats',
        permission: ResellerStatsPermissions.RESELLER_STATS_READ,
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
        ],
      },
    ],
    routes: [],
  },
  {
    id: uuidv4(),
    name: tn('peopleManagement', 'People Management'),
    engName: 'People Management',
    path: '',
    permission: '',
    subTabs: [
      {
        id: uuidv4(),
        name: tn('captureLogs', 'Capture Logs'),
        engName: 'Capture Logs',
        permission: '',
        path: 'capture-logs',
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
          {
            name: 'Configuration',
            icon: <DisplaySettingsIcon />,
            url: 'configure',
            permission:
              AccessControlPermissions.PEOPLE_MANAGEMENT_CONFIGURE_READ,
          },
        ],
        tab_access: 'capture_logs',
      },
      {
        id: uuidv4(),
        name: tn('accessControl', 'Access Control'),
        engName: 'Access Control',
        permission: '',
        path: 'access-control',
        routes: [
          {
            name: 'Dashboard',
            icon: <HomeIcon />,
            url: 'dashboard',
            permission: '',
          },
          {
            name: 'Access Logs',
            icon: <CommentIcon />,
            url: 'access-logs',
            permission:
              AccessControlPermissions.ACCESS_CONTROL_VIEW_ACCESS_LOGS_PAGE,
          },
          {
            name: 'Identity Access Management',
            icon: <ManageAccountsIcon />,
            url: 'iam',
            permission: AccessControlPermissions.ACCESS_CONTROL_VIEW_IAM,
          },
          {
            name: 'Configuration',
            icon: <DisplaySettingsIcon />,
            url: 'configure',
            permission:
              AccessControlPermissions.PEOPLE_MANAGEMENT_CONFIGURE_READ,
          },
        ],
        tab_access: 'access_control',
      },
    ],
    routes: [],
  },
  {
    id: uuidv4(),
    name: tn('timelapse', 'Timelapse'),
    engName: 'Timelapse',
    path: 'timelapse',
    permission: '',
    tab_access: 'timelapse',
    routes: [
      {
        name: tn('log', 'Log'),
        icon: <EditNoteIcon />,
        url: 'log',
        permission: '',
      },
      {
        name: tn('gallery', 'Gallery'),
        icon: <CollectionsIcon />,
        url: 'gallery',
        permission: '',
      },
    ],
  },
];

export const useAuthorisedSections = () => {
  // Only the sections that current user has access to

  const { data: curUser } = useUsersCurrentRetrieveQuery();

  const sectionsResult = sections();

  return useMemo(() => {
    return sectionsResult
      .filter((section) => {
        if (section.subTabs) {
          return section.subTabs.some((subTab) =>
            subTab.tab_access ? curUser?.tab_access[subTab.tab_access] : true,
          );
        }

        return section.tab_access
          ? curUser?.tab_access[section.tab_access]
          : true;
      })
      .sort((a, b) => a.engName.localeCompare(b.engName));
  }, [curUser, sectionsResult]);
};

export default sections;
