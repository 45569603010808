import React from 'react';

import { Stack, Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { ConfirmationDialogProps } from './types';

type IProps = Pick<
  ConfirmationDialogProps,
  'confirmationText' | 'onConfirm' | 'confirmText' | 'cancelText'
> & {
  confirmationInputVal: string;
  handleCancel: () => void;
  cancelProps?: ButtonProps;
  otp?: string;
};

const ConfirmationFooter = ({
  confirmationInputVal,
  confirmationText,
  onConfirm,
  confirmText,
  cancelText,
  handleCancel,
  cancelProps,
  otp,
}: IProps) => {
  const { t } = useTranslation('common');
  return (
    <Stack justifyContent="flex-end" direction="row" spacing={2}>
      <Button
        disabled={
          (confirmationText && confirmationText !== confirmationInputVal) ||
          otp === '' ||
          false
        }
        onClick={onConfirm}
        variant="contained"
        color="secondary"
        sx={{ minWidth: '8rem' }}
      >
        {confirmText || t('common.yes', 'Yes')}
      </Button>
      <Button
        sx={{ minWidth: '8rem' }}
        onClick={handleCancel}
        variant="contained"
        color="info"
        {...cancelProps}
      >
        {cancelText || t('common.no', 'No')}
      </Button>
    </Stack>
  );
};

export default ConfirmationFooter;
