import React from 'react';

import { Box, Button, SvgIconTypeMap, Typography } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface IProps {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string;
  };
  title: string;
  subTitle?: string;
  cta?: React.ReactNode;
}

const ErrorPage = ({ Icon, title, subTitle, cta }: IProps) => {
  return (
    <Box
      sx={{
        width: '75vw',
        height: '75vh',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '1rem',
      }}
    >
      <Icon sx={{ fontSize: '10em' }} />
      <Typography textAlign="center" variant="h2">
        {title}
      </Typography>
      {subTitle && (
        <Typography textAlign="center" variant="h5">
          {subTitle}
        </Typography>
      )}
      {cta && <Button variant="contained">{cta}</Button>}
    </Box>
  );
};

export default ErrorPage;
