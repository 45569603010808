import React, { ComponentProps } from 'react';

import {
  Autocomplete,
  List,
  ListSubheader,
  Stack,
  TextField,
} from '@mui/material';

import { useResellersGroupCompaniesListQuery } from 'services/api/general';
import { FormFieldDescription } from './Forms';

interface IProps extends ComponentProps<typeof FormFieldDescription> {
  onChange: (companyId: string | undefined) => void;
  companyId: string | null;
  title?: string;
  subtitle?: string;
}

const CompaniesGroupedByResellerAutocomplete = ({
  onChange,
  companyId,
  ...formFieldProps
}: IProps) => {
  const { data: companies } = useResellersGroupCompaniesListQuery(undefined, {
    selectFromResult: ({ data, ...rest }) => {
      const companiesGroupedByReseller = data
        ?.map((reseller) =>
          reseller.companies.map((company) => ({
            ...company,
            resellerName: reseller.name,
            resellerId: reseller.id,
          })),
        )
        .flat();

      return {
        ...rest,
        data: companiesGroupedByReseller,
      };
    },
  });

  return (
    <Stack>
      <FormFieldDescription {...formFieldProps} />
      <Autocomplete
        value={companies?.find((option) => option.id === companyId) || null}
        groupBy={(option) => option.resellerName}
        renderGroup={(params) => (
          <>
            <ListSubheader sx={{ fontWeight: 'bold', color: 'text.primary' }}>
              {params.group}
            </ListSubheader>
            <List sx={{ pl: 2 }} disablePadding>
              {params.children}
            </List>
          </>
        )}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} size="small" />}
        options={companies || []}
        onChange={(_, newOption) => onChange(newOption?.id)}
      />
    </Stack>
  );
};

export default CompaniesGroupedByResellerAutocomplete;
