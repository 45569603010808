import React from 'react';

import { Button } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router';

import {
  useAuthLogoutMutation,
  useUsersCurrentRetrieveQuery,
  useUsersDestroyMutation,
} from 'services/api/general';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import WithPermissions from 'components/common/Permissions';
import UserFormPermissions from '../UserFormPermissions';

interface IProps {
  userName: string;
  userId: number;
}

const DeleteUser = ({ userName, userId }: IProps) => {
  const [deleteUserMutation] = useUsersDestroyMutation();
  const navigate = useNavigate();
  const [logout] = useAuthLogoutMutation();
  const { data: currentUser } = useUsersCurrentRetrieveQuery();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteUser = () => {
    deleteUserMutation({
      id: userId,
    })
      .unwrap()
      .then(() => {
        enqueueSnackbar(`User has been deleted successfully`, {
          variant: 'success',
        });

        if (currentUser?.id === userId) {
          logout()
            .unwrap()
            .then(() => {
              navigate('/login');
            });
        }
      })
      .catch((err) => {
        enqueueSnackbar(JSON.stringify(err) || 'Something went wrong', {
          variant: 'error',
        });
      });
  };

  return (
    <ConfirmationDialog
      title="Are you sure?"
      description="This action can't be undone. Are you sure?"
      confirmationText={`Delete ${userName}`}
      onConfirm={handleDeleteUser}
      triggerButton={
        <Button variant="contained" color="warning">
          Delete User
        </Button>
      }
    />
  );
};

export default (props: IProps) => (
  <WithPermissions permission={UserFormPermissions.ALLOW_DELETE_USERS}>
    <DeleteUser {...props} />
  </WithPermissions>
);
