import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'store';

export type PpeDetectionMetadata = {
  missing_hat: boolean;
  missing_vest: boolean;
  missing_safetyharness: boolean;
  missing_facemask: boolean;
  missing_boots: boolean;
  missing_gloves: boolean;
  missing_lifevest: boolean;
};
export type FireSmokeMetadata = {
  fire: boolean;
  smoke: boolean;
};

export type AlertMetadataState = {
  ppe_detection: PpeDetectionMetadata;
  fire_smoke_detection: FireSmokeMetadata;
  worker_near_heavy_machine: string[];
  worker_under_load: string[];
};

const initialState: AlertMetadataState = {
  ppe_detection: {
    missing_hat: false,
    missing_vest: false,
    missing_safetyharness: false,
    missing_facemask: false,
    missing_boots: false,
    missing_gloves: false,
    missing_lifevest: false,
  },
  fire_smoke_detection: {
    fire: false,
    smoke: false,
  },
  worker_near_heavy_machine: [],
  worker_under_load: [],
};

const alertMetadataFilterSlice = createSlice({
  name: 'alertMetadataFilter',
  initialState,
  reducers: {
    updatePpeDetectionFilters: (
      state,
      action: PayloadAction<PpeDetectionMetadata>,
    ) => {
      state.ppe_detection = action.payload;
    },
    updateFireSmokeFilters: (
      state,
      action: PayloadAction<FireSmokeMetadata>,
    ) => {
      state.fire_smoke_detection = action.payload;
    },
    updateWorkerNearMachineryFilters: (
      state,
      action: PayloadAction<string[]>,
    ) => {
      state.worker_near_heavy_machine = action.payload;
    },
    updateWorkerUnderLoadFilters: (state, action: PayloadAction<string[]>) => {
      state.worker_under_load = action.payload;
    },
    resetAlertMetadataFilters: (state) => {
      state.ppe_detection = initialState.ppe_detection;
      state.worker_under_load = initialState.worker_under_load;
      state.fire_smoke_detection = initialState.fire_smoke_detection;
      state.worker_near_heavy_machine = initialState.worker_near_heavy_machine;
    },
  },
});

export const getAlertMetadataFilters = (state: RootState) =>
  state.alertMetadataFilter;

export const {
  updatePpeDetectionFilters,
  updateFireSmokeFilters,
  updateWorkerNearMachineryFilters,
  updateWorkerUnderLoadFilters,
  resetAlertMetadataFilters,
} = alertMetadataFilterSlice.actions;

export default alertMetadataFilterSlice.reducer;
