import { useSnackbar } from 'notistack';

import { useUsersResendAccountInvitationMutation } from 'services/api/general';

const useHandleResendButtonClick = () => {
  const [triggerResendUserInvite, { isLoading }] =
    useUsersResendAccountInvitationMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (userId: number, first_name: string) =>
    triggerResendUserInvite({
      id: userId,
    })
      .unwrap()
      .then((resp) => {
        enqueueSnackbar(`Successfully resent invitation to ${first_name}.`, {
          variant: 'success',
        });
        return resp;
      })
      .catch((err) => {
        enqueueSnackbar(
          `Resending invitation to ${first_name} failed: ${JSON.stringify(
            err,
          )}`,
          {
            variant: 'error',
          },
        );
      });

  return {
    handleClick,
    isLoading,
  };
};

export default useHandleResendButtonClick;
