import React, { useState } from 'react';

import { IconButton, Stack, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import ColumnHeader from '../ColumnHeader';

interface IProps {
  searchColVal: string;
  setSearchColVal: React.Dispatch<React.SetStateAction<string>>;
  columnHeader: string;
  selectAllComponent?: JSX.Element;
}

const ColumnHeaderWithSearch = ({
  searchColVal,
  setSearchColVal,
  columnHeader,
  selectAllComponent,
}: IProps) => {
  const [displaySearchField, setDisplaySearchField] = useState(false);

  const handleDisplaySearchField = () => {
    setDisplaySearchField(true);
  };

  const handleHideSearchField = () => {
    setTimeout(() => {
      setDisplaySearchField(false);
      setSearchColVal('');
    }, 200);
  };

  return (
    <ColumnHeader
      action={
        !displaySearchField && (
          <IconButton onClick={handleDisplaySearchField}>
            <SearchIcon />
          </IconButton>
        )
      }
    >
      {displaySearchField ? (
        <TextField
          value={searchColVal}
          onChange={(e) => setSearchColVal(e.target.value)}
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleHideSearchField}>
                <ClearIcon />
              </IconButton>
            ),
          }}
          autoFocus
          size="small"
          label={`Search ${columnHeader}`}
          onBlur={handleHideSearchField}
        />
      ) : (
        <Stack direction="row" alignItems="center">
          {selectAllComponent}
          {columnHeader}
        </Stack>
      )}
    </ColumnHeader>
  );
};

export default ColumnHeaderWithSearch;
