import { useSitesSiteSiteGroupingsQuery } from 'services/api';
import { SitesGetFrZoneInfoRetrieveApiResponse } from 'services/api/ailyssaApi';
import { SelectedItems } from '../types';

const useFind = (data?: SitesGetFrZoneInfoRetrieveApiResponse) => {
  const { data: defaultData } = useSitesSiteSiteGroupingsQuery();

  if (!data) {
    data = defaultData;
  }

  const handleFindColAndScoWithSct = (subColTwo: string[]) => {
    const items: SelectedItems = {
      column: [],
      subColOne: [],
      subColTwo,
    };

    data?.forEach((site) => {
      site.groups.forEach((group) => {
        const camIds = group.cameras.map((c) => c.id);
        if (subColTwo.some((sct) => camIds.includes(sct))) {
          items.column.push(site.id);
          items.subColOne.push(group.id);
        }
      });
    });
    return items;
  };

  return handleFindColAndScoWithSct;
};

export const useFindColWithSco = () => {
  const { data } = useSitesSiteSiteGroupingsQuery();

  const handleFindColWithSco = (scoId: string) =>
    data?.find((site) => site.groups.find((gp) => gp.id === scoId));

  return handleFindColWithSco;
};

export default useFind;
