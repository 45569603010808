import emptySplitApi from 'services/api/ailyssaBaseApi';

import {
  UsersListApiResponse,
  UsersListApiArg,
  UsersCreateApiResponse,
  UsersCreateApiArg,
  UsersRetrieveApiResponse,
  UsersRetrieveApiArg,
  UsersUpdateApiResponse,
  UsersUpdateApiArg,
  UsersPartialUpdateApiResponse,
  UsersPartialUpdateApiArg,
  UsersDestroyApiResponse,
  UsersDestroyApiArg,
  UsersCurrentRetrieveApiResponse,
  UsersCurrentRetrieveApiArg,
  UsersGroupsListApiResponse,
  UsersGroupsListApiArg,
  UsersGroupsCreateApiResponse,
  UsersGroupsCreateApiArg,
  UsersGroupsRetrieveApiResponse,
  UsersGroupsRetrieveApiArg,
  UsersGroupsUpdateApiResponse,
  UsersGroupsUpdateApiArg,
  UsersGroupsPartialUpdateApiResponse,
  UsersGroupsPartialUpdateApiArg,
  UsersGroupsDestroyApiResponse,
  UsersGroupsDestroyApiArg,
  UsersPermissionsCreateApiArg,
  UsersPermissionsCreateApiResponse,
  UsersPermissionsDestroyApiArg,
  UsersPermissionsDestroyApiResponse,
  UsersPermissionsListApiArg,
  UsersPermissionsListApiResponse,
  UsersPermissionsPartialUpdateApiArg,
  UsersPermissionsPartialUpdateApiResponse,
  UsersPermissionsRetrieveApiArg,
  UsersPermissionsRetrieveApiResponse,
  UsersPermissionsUpdateApiArg,
  UsersPermissionsUpdateApiResponse,
  UsersNotificationsListApiResponse,
  UsersNotificationsListApiArg,
  UsersReadAllNotificationsCreateApiArg,
  UsersReadAllNotificationsCreateApiResponse,
  UsersReadNotificationCreateApiResponse,
  UsersReadNotificationCreateApiArg,
  UsersUnreadNotificationCountRetrieveApiArg,
  UsersUnreadNotificationCountRetrieveApiResponse,
  UsersUploadCsvCreateApiResponse,
  UsersUploadCsvCreateApiArg,
  UsersActivateAccountCreateApiResponse,
  UsersActivateAccountCreateApiArg,
  UsersResendAccountInvitationApiResponse,
  UsersResendAccountInvitationApiArg,
  UsersInternalUsersListApiResponse,
  UsersInternalUsersListApiArg,
  UsersAddUserPermissionsCreateApiResponse,
  UsersAddUserPermissionsCreateApiArg,
  UsersRemoveUserPermissionsCreateApiResponse,
  UsersRemoveUserPermissionsCreateApiArg,
  UsersResetPermissionsCreateApiArg,
} from './types';

const usersApi = emptySplitApi.injectEndpoints({
  endpoints: (build) => ({
    usersList: build.query<UsersListApiResponse, UsersListApiArg>({
      query: (queryArg) => ({
        url: '/users/',
        params: {
          companies: queryArg.companies,
          external_only: queryArg.externalOnly,
          internal_only: queryArg.internalOnly,
          sites: queryArg.sites,
          email: queryArg.userFilters?.email || undefined,
          first_name: queryArg.userFilters?.firstName || undefined,
          groups: queryArg.userFilters?.groups,
          last_name: queryArg.userFilters?.lastName || undefined,
          user_channel_id: queryArg.userFilters?.userChannelId || undefined,
          username: queryArg.userFilters?.username || undefined,
        },
      }),
      providesTags: ['User'],
    }),
    usersCreate: build.mutation<UsersCreateApiResponse, UsersCreateApiArg>({
      query: (queryArg) => ({
        url: '/users/',
        method: 'POST',
        body: queryArg.user,
      }),
      invalidatesTags: ['CompanyUser', 'ResellerUser', 'User'],
    }),
    usersRetrieve: build.query<UsersRetrieveApiResponse, UsersRetrieveApiArg>({
      query: (queryArg) => ({ url: `/users/${queryArg.id}/` }),
      providesTags: (result) =>
        result ? [{ type: 'User', id: result.username }, 'User'] : ['User'],
    }),
    usersUpdate: build.mutation<UsersUpdateApiResponse, UsersUpdateApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.user,
      }),
      invalidatesTags: ['CompanyUser', 'ResellerUser', 'User'],
    }),
    usersPartialUpdate: build.mutation<
      UsersPartialUpdateApiResponse,
      UsersPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedUser,
      }),
      invalidatesTags: ['CompanyUser', 'ResellerUser', 'User'],
    }),
    usersDestroy: build.mutation<UsersDestroyApiResponse, UsersDestroyApiArg>({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['CompanyUser', 'ResellerUser'],
    }),
    usersCurrentRetrieve: build.query<
      UsersCurrentRetrieveApiResponse,
      UsersCurrentRetrieveApiArg
    >({
      query: () => ({ url: '/users/current/' }),
      providesTags: (result) =>
        result ? [{ type: 'User', id: result.id }] : ['User'],
    }),
    usersGroupsList: build.query<
      UsersGroupsListApiResponse,
      UsersGroupsListApiArg
    >({
      query: () => ({ url: '/users/roles/' }),
      providesTags: ['UsersGroups'],
    }),
    usersGroupsCreate: build.mutation<
      UsersGroupsCreateApiResponse,
      UsersGroupsCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/users/roles/',
        method: 'POST',
        body: queryArg.group,
      }),
    }),
    usersGroupsRetrieve: build.query<
      UsersGroupsRetrieveApiResponse,
      UsersGroupsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/users/roles/${queryArg.id}/` }),
      providesTags: (result, error, arg) => [
        { type: 'UsersGroups', id: arg.id },
      ],
    }),
    usersGroupsUpdate: build.mutation<
      UsersGroupsUpdateApiResponse,
      UsersGroupsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/roles/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.group,
      }),
    }),
    usersGroupsPartialUpdate: build.mutation<
      UsersGroupsPartialUpdateApiResponse,
      UsersGroupsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/roles/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedGroup,
      }),
      invalidatesTags: () => [{ type: 'UsersGroups' }],
    }),
    usersGroupsDestroy: build.mutation<
      UsersGroupsDestroyApiResponse,
      UsersGroupsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/roles/${queryArg.id}/`,
        method: 'DELETE',
      }),
    }),
    usersPermissionsList: build.query<
      UsersPermissionsListApiResponse,
      UsersPermissionsListApiArg
    >({
      query: () => ({ url: '/users/permissions/' }),
    }),
    usersPermissionsCreate: build.mutation<
      UsersPermissionsCreateApiResponse,
      UsersPermissionsCreateApiArg
    >({
      query: (queryArg) => ({
        url: '/users/permissions/',
        method: 'POST',
        body: queryArg.permissionRetrieve,
      }),
    }),
    usersPermissionsRetrieve: build.query<
      UsersPermissionsRetrieveApiResponse,
      UsersPermissionsRetrieveApiArg
    >({
      query: (queryArg) => ({ url: `/users/permissions/${queryArg.id}/` }),
      providesTags: ['UsersPermissions'],
    }),
    usersPermissionsUpdate: build.mutation<
      UsersPermissionsUpdateApiResponse,
      UsersPermissionsUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/permissions/${queryArg.id}/`,
        method: 'PUT',
        body: queryArg.permissionRetrieve,
      }),
    }),
    usersPermissionsPartialUpdate: build.mutation<
      UsersPermissionsPartialUpdateApiResponse,
      UsersPermissionsPartialUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/permissions/${queryArg.id}/`,
        method: 'PATCH',
        body: queryArg.patchedPermissionRetrieve,
      }),
    }),
    usersPermissionsDestroy: build.mutation<
      UsersPermissionsDestroyApiResponse,
      UsersPermissionsDestroyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/permissions/${queryArg.id}/`,
        method: 'DELETE',
      }),
    }),
    usersUnreadNotificationCountRetrieve: build.query<
      UsersUnreadNotificationCountRetrieveApiResponse,
      UsersUnreadNotificationCountRetrieveApiArg
    >({
      query: () => ({ url: `/users/unread_notification_count/` }),
      onQueryStarted: async (
        _,
        { dispatch, queryFulfilled, getCacheEntry },
      ) => {
        const cachedData = getCacheEntry().data;
        const { data: newData } = await queryFulfilled;

        if (cachedData && cachedData !== newData) {
          dispatch(emptySplitApi.util.invalidateTags(['UserNotifications']));
        }
      },
      providesTags: ['UserUnreadNotificationsCount'],
    }),
    usersNotificationsList: build.query<
      UsersNotificationsListApiResponse,
      UsersNotificationsListApiArg
    >({
      query: (queryArg) => ({
        url: `/users/notifications/`,
        params: {
          page: queryArg.page,
          tab_name: queryArg.tabName,
          type: queryArg.type,
        },
      }),
      providesTags: ['UserNotifications'],
    }),
    usersReadNotificationCreate: build.mutation<
      UsersReadNotificationCreateApiResponse,
      UsersReadNotificationCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/read_notification/`,
        method: 'POST',
        body: queryArg.userNotificationReadRequest,
      }),
      invalidatesTags: [
        'UserNotifications',
        'UserUnreadNotificationsCount',
        'AlertBase',
      ],
    }),
    usersReadAllNotificationsCreate: build.mutation<
      UsersReadAllNotificationsCreateApiResponse,
      UsersReadAllNotificationsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/read_all_notifications/`,
        method: 'POST',
        body: queryArg.userNotificationsReadAllRequest,
      }),
      invalidatesTags: [
        'UserNotifications',
        'UserUnreadNotificationsCount',
        'AlertBase',
      ],
    }),
    usersUploadCsvCreate: build.mutation<
      UsersUploadCsvCreateApiResponse,
      UsersUploadCsvCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/upload_csv/`,
        method: 'POST',
        body: queryArg,
        formData: true,
      }),
      invalidatesTags: ['User', 'CompanyUser'],
    }),
    usersActivateAccountCreate: build.mutation<
      UsersActivateAccountCreateApiResponse,
      UsersActivateAccountCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/activate_account/`,
        method: 'POST',
        body: queryArg.activateAccountRequest,
      }),
      invalidatesTags: (result, error, arg) =>
        arg.activateAccountRequest.user_id
          ? [{ type: 'User', id: arg.activateAccountRequest.user_id }]
          : ['User'],
    }),
    usersResendAccountInvitation: build.mutation<
      UsersResendAccountInvitationApiResponse,
      UsersResendAccountInvitationApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/send_invitation_email/`,
        method: 'POST',
      }),
    }),
    usersListInternalUsers: build.query<
      UsersInternalUsersListApiResponse,
      UsersInternalUsersListApiArg
    >({
      query: (queryArg) => ({
        url: `/users/internal_users`,
        params: {
          first_name:
            queryArg.firstName.length > 0 ? queryArg.firstName : undefined,
          last_name:
            queryArg.lastName.length > 0 ? queryArg.lastName : undefined,
          email: queryArg.email.length > 0 ? queryArg.email : undefined,
          username:
            queryArg.username.length > 0 ? queryArg.username : undefined,
          groups: queryArg.groups,
          user_channel_id: queryArg.userChannelId || undefined,
        },
      }),
      providesTags: ['ResellerUser'],
    }),
    usersAddUserPermissionsCreate: build.mutation<
      UsersAddUserPermissionsCreateApiResponse,
      UsersAddUserPermissionsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/add_user_permissions/`,
        method: 'POST',
        body: queryArg.addPermissionsToUserRequest,
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: 'User', id: arg.addPermissionsToUserRequest.username },
        'UsersPermissions',
      ],
    }),
    usersRemoveUserPermissionsCreate: build.mutation<
      UsersRemoveUserPermissionsCreateApiResponse,
      UsersRemoveUserPermissionsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/remove_user_permissions/`,
        method: 'POST',
        body: queryArg.removePermissionsFromUserRequest,
      }),
      invalidatesTags: (_result, _err, arg) => [
        { type: 'User', id: arg.removePermissionsFromUserRequest.username },
        'UsersPermissions',
      ],
    }),

    usersResetPermissionsCreate: build.mutation<
      void,
      UsersResetPermissionsCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/${queryArg.id}/reset_permissions/`,
        method: 'POST',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'User', id: arg.username },
        'UsersPermissions',
      ],
    }),
  }),
});

export const {
  useUsersListQuery,
  useUsersCreateMutation,
  useUsersRetrieveQuery,
  useUsersUpdateMutation,
  useUsersPartialUpdateMutation,
  useUsersDestroyMutation,
  useUsersCurrentRetrieveQuery,
  useUsersGroupsListQuery,
  useUsersGroupsCreateMutation,
  useUsersGroupsRetrieveQuery,
  useUsersGroupsUpdateMutation,
  useUsersGroupsPartialUpdateMutation,
  useUsersGroupsDestroyMutation,
  useUsersPermissionsListQuery,
  useUsersPermissionsCreateMutation,
  useUsersPermissionsRetrieveQuery,
  useUsersPermissionsUpdateMutation,
  useUsersPermissionsPartialUpdateMutation,
  useUsersPermissionsDestroyMutation,
  useUsersUnreadNotificationCountRetrieveQuery,
  useUsersNotificationsListQuery,
  useUsersReadAllNotificationsCreateMutation,
  useUsersReadNotificationCreateMutation,
  useUsersUploadCsvCreateMutation,
  useUsersActivateAccountCreateMutation,
  useUsersResendAccountInvitationMutation,
  useUsersListInternalUsersQuery,
  useUsersAddUserPermissionsCreateMutation,
  useUsersRemoveUserPermissionsCreateMutation,
  useUsersResetPermissionsCreateMutation,
} = usersApi;

export default usersApi;

export * from './types';
