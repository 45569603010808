import React, { FC, PropsWithoutRef } from 'react';

import { Box } from '@mui/material';
import type { User } from 'ts_shared';
import { useTranslation } from 'react-i18next';

import CompaniesGroupedByResellerAutocomplete from 'components/common/CompaniesGroupedByResellerAutocomplete';
import ControllerWithError from 'libs/react-hook-form/ControllerWithError';
import type { ResourceFormProps } from 'types';
import { FormFieldDescription } from 'components/common/Forms';
import UserSitesSelectDropdown from './UserSitesSelectDropdown';
import UserGroupSelectDropdown from './UserGroupSelectDropdown';
import UserResellerDropdown from './UserResellerDropdown';
import { UserFormUserType } from '../../types';

type FormProps = PropsWithoutRef<ResourceFormProps<User>>;

const ExternalUserPermsConfig = ({ control, setValue, watch }: FormProps) => {
  const { t } = useTranslation('common');

  return (
    <>
      <ControllerWithError
        control={control}
        name="company"
        render={({ field: { onChange, value } }) => (
          <CompaniesGroupedByResellerAutocomplete
            required
            title={t('settings.userCompany', 'User Company')}
            subtitle={t(
              'settings.companyUserBelongsTo',
              'Company to which the user belongs',
            )}
            companyId={value}
            onChange={(newCompanyId) => {
              onChange(newCompanyId);
              if (setValue) setValue('sites', []);
            }}
          />
        )}
      />
      <Box sx={{ display: 'grid' }}>
        <FormFieldDescription
          title={t('settings.userSites', 'User Sites')}
          subtitle={t(
            'settings.siteUserBelongsTo',
            'The site that the user belongs to',
          )}
          required
        />
        <UserSitesSelectDropdown control={control} watch={watch} />
      </Box>
    </>
  );
};

interface IProps extends FormProps {
  userType: UserFormUserType;
}

const userTypeMapping: Record<UserFormUserType, FC<IProps> | null> = {
  current: null,
  internal: UserResellerDropdown,
  external: ExternalUserPermsConfig,
};

const UserFormPermsConfigSection = ({ ...props }: IProps) => {
  const { t } = useTranslation('common');

  const { userType, control } = props;

  const ConfigSection = userTypeMapping[userType];

  return (
    <Box
      sx={{
        display: 'grid',
        width: '100%',
        gap: 2,
      }}
    >
      <Box sx={{ display: 'grid' }}>
        <FormFieldDescription
          title={t('settings.userGroup', 'User Group')}
          subtitle={t(
            'settings.userGroupUserBelongsTo',
            'The user group the user belongs to',
          )}
          required
        />
        <UserGroupSelectDropdown control={control} userType={userType} />
        {ConfigSection && <ConfigSection {...props} />}
      </Box>
    </Box>
  );
};

export default UserFormPermsConfigSection;
