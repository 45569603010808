import React from 'react';

import {
  Pagination,
  PaginationItem,
  PaginationProps,
  useMediaQuery,
} from '@mui/material';

import PaginationCurrentPageInput from './PaginationCurrentPageInput';

interface IProps {
  total_pages: number;
  paginationProps?: PaginationProps;
  page: number;
  onChange: (pageNumber: number) => void;
}

const PaginationWithJumpTo = ({
  total_pages,
  paginationProps,
  page,
  onChange,
}: IProps) => {
  const matches = useMediaQuery('(min-width:2000px)');

  return (
    <Pagination
      color="primary"
      count={total_pages || 0}
      sx={{ display: 'flex', justifyContent: 'center' }}
      page={Number(page)}
      onChange={(e, v) => onChange(v)}
      size={matches ? 'medium' : 'small'}
      renderItem={(params) => {
        if (params.page === Number(page)) {
          return (
            <PaginationCurrentPageInput
              page={page}
              onChange={onChange}
              total_pages={total_pages}
            />
          );
        }
        return <PaginationItem {...params} />;
      }}
      {...paginationProps}
    />
  );
};

export default PaginationWithJumpTo;
